import { ALL_PAGES } from "../Storage";

/**
 * Iterates over all the gvos in each snippet generating the object piece
 * for each iteration. Once we have all pieces we merge them together
 * generating the object that will be stored in the localStorage.
 * @param {object} data response from the endpoint
 */
export function responseAdapter(data) {
  const pieces = data.configItems.map(item => {
    return item.assignments.gvos.map(gvo => {
      return getSnippetPieces(item, gvo);
    });
  });

  return {
    snippets: pieces.flat(2),
    config: {
      disabled: data.snippetsDisabled,
      showDebugContent: data.showDebugContent
    }
  };
}

/**
 * Generates the desired object shape for each selected snippet and gvo.
 * @param {object} item current snippet in the iteration
 * @param {object} gvo current gvo in the iteration
 */
export function getSnippetPieces(item, gvo) {
  const { position, language, notificationId } = item.assignments;

  const generatePiece = page => ({
    [position]: {
      [language]: {
        [gvo.name.toLowerCase()]: {
          [page.toLowerCase()]: notificationId
            ? { [notificationId]: [item] }
            : { empty: [item] }
        }
      }
    }
  });

  if (gvo.allPages) {
    return [generatePiece(ALL_PAGES)];
  } else {
    return gvo.pages.map(generatePiece);
  }
}
