/**
 * Fetches the configuration endpoint with the correct parameters and
 * returns a promise that will be used by `createPosition`.
 * @param {object} config
 */
export function snippetConfigurationFetcher({
  apiServer,
  license,
  channel = "desktop",
  tenant
}) {
  return window
    .fetch(
      `${apiServer}/trxmapi/v1/${tenant}/services/snippets/cms/config?channel=${channel}`,
      {
        mode: "cors",
        headers: {
          "X-License-Key": license
        }
      }
    )
    .then(response => response.json());
}
