import { STORAGE_ID } from "../Storage";

/**
 * Takes replacer parameter from localStorage and in case it exists applies
 * transformation to html.
 * @param {string} html to be transformed
 */
export function locationTransformer(
  html,
  replacer = resourcesLocationRewriter
) {
  const data = localStorage.getItem(STORAGE_ID);
  let locationRewriter;
  if (data !== null) {
    locationRewriter = JSON.parse(data).config.adobeResourcesLocationRewriter;
  }
  // locationRewriter can be 'null', so extra check is needed
  return typeof locationRewriter !== "undefined" && locationRewriter
    ? replacer(html, locationRewriter)
    : html;
}

/**
 * Takes the answers of surveys and in case they are placed, updates the html with
 * the required checks.
 * @param {string} html to be transformed
 * @param {array<object>} answers customer survey answers
 */
export function updateSurveyAnswers(html, answers) {
  if (!answers) {
    return html;
  }

  const formContainerElement = document.createElement("div");
  formContainerElement.innerHTML = html;

  answers.forEach(answer => {
    const selectedInputArray = formContainerElement.querySelectorAll(
      `input[name=${answer.name}]`
    );
    if (selectedInputArray) {
      const selectedInput = selectedInputArray[answer.value - 1];
      if (selectedInput) {
        selectedInput.setAttribute("checked", true);
      }
    }
  });
  return formContainerElement.innerHTML;
}

/**
 * Replaces the url of an href for the correctly formatted one in case it has `GVO_`.
 * The format of the url that will be converted is `GVO_name?params`.
 * @param {string} url href url that will be converted
 * @param {string} tenant used in the url construction
 */
export function urlReplacer(url, tenant) {
  const [id, params] = url.split("?");
  const gvo = id.split("_")[1];
  const optionalParams = params ? `&${params}` : "";
  const host = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }`;

  return `${host}/trxm/${tenant}/goto.dialog.do?gvo=${gvo}&directAccess=true${optionalParams}`;
}

/**
 * Applies `replacer` function to all the links with GVO_ in the passed `html`.
 * String.replace passes lots of parameters into the `replacer` function we
 * only need the first one.
 * @param {string} html snippet
 * @param {function} replacer function that will be applied to link strings
 * @param {string} tenant used in the url construction
 */
export function hrefRewriter(html, replacer, tenant) {
  return html.replace(/GVO_(.*)(?=(?:"))/gm, match => replacer(match, tenant));
}

/**
 * Applies `replacer` string to all the images sources with an specific pattern in the passed `html`.
 * @param {string} html snippet
 * @param {object} replacer object that contains the pattern and the replacement that will be applied to src strings
 */
export function resourcesLocationRewriter(html, replacer) {
  const regex = new RegExp(
    `<img([^>]*)(src=['"])(${replacer.pattern})(.*?)(['"][^>]*>)`,
    "gi"
  );
  return html.replace(regex, `<img$1$2${replacer.replace}$4$5`);
}

/**
 * Takes raw snippet html and applies transformations.
 * @param {string} html
 * @param {object} config
 */
export function transformHtml(html, config) {
  return new Promise((resolve, reject) => {
    try {
      let transformedHtml = hrefRewriter(html, urlReplacer, config.tenant);
      transformedHtml = locationTransformer(transformedHtml);
      transformedHtml = updateSurveyAnswers(transformedHtml, config.answers);

      resolve(transformedHtml);
    } catch (error) {
      reject(error);
    }
  });
}
