/* eslint-disable no-console */
export function log(message, args = "color: green;") {
  if (__SHOW_LOGS__) {
    console.log(`%c${message}`, args);
  }
}

export function group(header, itemsToRender) {
  if (__SHOW_LOGS__) {
    console.group(`%c${header}`, "color: forestgreen; font-size: 14px;");
    itemsToRender().forEach(item => log(`\t${item}`));
    console.groupEnd();
  }
}
/* eslint-enable no-console */
