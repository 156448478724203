import { snippetConfigurationFetcher } from "./api";
import { responseAdapter } from "./adapter";
import { log } from "../Logger";
import { clean, STORAGE_ID } from "../Storage";
import { isEquivalent } from "./utils";

/**
 * Singleton pattern to generate only one instance of the store.
 */
let cmsStore;
export function getStore(initialState) {
  if (cmsStore === undefined) {
    cmsStore = generateStore(initialState);
  }
  return cmsStore;
}

/**
 * Creates a singleton returning the download promise.
 * When we don't have data in the localStorage this allows all the snippets to
 * use the same promise avoiding multiple calls to the endpoint in the login page.
 */
let downloadPromise = undefined;
export function subscribeToPromise(config) {
  if (downloadPromise === undefined) {
    downloadPromise = snippetConfigurationFetcher(config);
  }
  return downloadPromise;
}

export function generateStore(
  initialState = {
    snippets: [],
    config: {}
  }
) {
  let state = initialState;
  syncState().then(nextState => (state = nextState)).then(setTimeout(() => {window.heightBalancing()}, 1000));

  function syncState() {
    const persistedState = localStorage.getItem(STORAGE_ID);
    if (persistedState) {
      const stateParsed = JSON.parse(persistedState);
      if (
        stateParsed.snippets !== undefined &&
        stateParsed.snippets.length === 0 &&
        stateParsed.config.apiServer !== undefined
      ) {
        return subscribeToPromise(stateParsed.config)
          .then(preprocess)
          .then(updateSnippets);
      }
      return Promise.resolve(stateParsed);
    } else {
      return Promise.resolve(initialState);
    }
  }

  function updateSnippets(payload) {
    state = getInternalState();
    const nextState = {
      ...state,
      snippets: payload.snippets
    };

    storeAndDispatchEvent(nextState);
  }

  function storeAndDispatchEvent(nextState) {
    state = nextState;
    localStorage.setItem(STORAGE_ID, JSON.stringify(nextState));
    window.dispatchEvent(
      new CustomEvent("cms-store-change", {
        detail: { state: nextState }
      })
    );
  }

  function setInitialState(stateParsed, payload) {
    const nextState = {
      snippets: payload.snippets,
      config: {
        ...stateParsed.config,
        ...payload.config
      }
    };

    storeAndDispatchEvent(nextState);
  }

  function setState(payload) {
    state = getInternalState();
    const nextState = {
      ...state,
      config: {
        ...state.config,
        ...payload
      }
    };

    if (!isEquivalent(state.config, nextState.config)) {
      storeAndDispatchEvent(nextState);
    }
  }

  function initialize(config) {
    clean();
    state = initialState;
    state.config = config;
    return subscribeToPromise(config)
      .then(preprocess)
      .then(payload => setInitialState(state, payload));
  }

  function preprocess(response) {
    if (
      typeof response.configItems === "undefined" ||
      response.configItems.length === 0
    ) {
      log("ERROR: Adobe not sending snippets...");
      return response;
    }

    return responseAdapter(response);
  }

  function getInternalState() {
    const persistedState = localStorage.getItem(STORAGE_ID);
    if (persistedState) {
      return JSON.parse(persistedState);
    } else {
      return initialState;
    }
  }

  function getState() {
    return getInternalState();
  }

  function showOrHideDebugMode() {
    setState({
      showDebugContent: !getInternalState().config.showDebugContent
    });
  }

  return {
    getState,
    initialize,
    setState,
    showOrHideDebugMode
  };
}
