export function createField(key, value) {
  const field = document.createElement("li");
  field.className = "debug_field";
  field.innerHTML = `
    <span class="key">${key}</span>
    <span class="value">${value}</span>
  `;
  return field;
}

export function createDebugElement(config) {
  const container = document.createElement("ul");
  container.id = `snippet_debug_${config.position}`;
  container.className = "snippet_debug";
  container.innerHTML = `
    <style>
      .snippet_debug {
        border: 1px solid #c2c2c2;
        padding: 1rem !important;
        font-size: 1rem;
      }

      .debug_field .key {
        color: gray;
        font-size: 0.75rem;
      }

      .debug_field .value {
        color: blue;
      }
    </style>
  `;

  const dataToDisplay = {
    position: config.position,
    tenant: config.tenant,
    language: config.language,
    gvoId: config.gvoId,
    page: config.page,
    customerGroup: config.customerGroup
  };

  Object.keys(dataToDisplay).forEach(key => {
    container.appendChild(createField(key, dataToDisplay[key]));
  });

  return container;
}
