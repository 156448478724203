import { fetchAppleMusicVoucher } from "./api";

/**
 * Singleton pattern to generate only one instance of the Voucher.
 */
export const voucher = Voucher();

function Voucher() {
  const state = {};
  function initialize(config) {
    state.config = { ...window.cmsStore.getState().config, ...config };
  }
  return {
    initialize,
    trxmCallVoucherApiApple: () => {
      return fetchAppleMusicVoucher(state.config).then(voucherResponse => {
        return voucherResponse.redemption_url;
      });
    }
  };
}
