import { ALL_PAGES } from "../Storage";
import { snippetHtmlFetcher } from "./api";
import { transformHtml } from "./transformer";
import { group, log } from "../Logger";

export function selectFromStorage(snippets, contextConfig) {
  const {
    position,
    customerGroup,
    language,
    notificationId = "empty"
  } = contextConfig;
  const page = contextConfig.page.toLowerCase();
  const gvoId = contextConfig.gvoId.toLowerCase();

  function getPageKey(snippet) {
    return snippet[position][language][gvoId][ALL_PAGES] !== undefined
      ? ALL_PAGES
      : page;
  }

  function getInfo(snippet) {
    // In case allPages exist it should have precedence over all of them
    const pageKey = getPageKey(snippet);
    return snippet[position][language][gvoId][pageKey][notificationId][0];
  }

  const foundSnippets = snippets
    .filter(snippet => {
      if (
        snippet[position] &&
        snippet[position][language] &&
        snippet[position][language][gvoId]
      ) {
        try {
          const info = getInfo(snippet);
          if (info) {
            // We take only the ones matching the customerGroup
            return info.assignments.customerGroups.includes(customerGroup);
          }
        } catch (error) {
          log("notificationId not found");
        }
      }

      return false;
    })
    .map(getInfo);

  if (foundSnippets.length > 1) {
    group(`Position ${position} has duplicates. First one selected.`, () => {
      return foundSnippets.map(({ url }) => `${url}`);
    });
  }

  return foundSnippets[0];
}

export function Snippet(snippets, contextConfig) {
  const snippet = selectFromStorage(snippets, contextConfig);
  return {
    getHeight: () => snippet.height,
    getHtml: () => {
      return snippetHtmlFetcher(snippet.url, contextConfig).then(html =>
        transformHtml(html, contextConfig)
      );
    }
  };
}
