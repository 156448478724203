import { CmsSnippet as customElement } from "./modules/Component/customElement";
import { getStore } from "./modules/Store";
import { voucher } from "./modules/Voucher";

window.customElements.get("cms-snippet") ||
  window.customElements.define("cms-snippet", customElement);

export { clean } from "./modules/Storage";
window.cmsStore = getStore();
window.cmsVoucher = voucher;
