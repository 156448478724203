import { Snippet } from "../Snippet";
import { log } from "../Logger";
import { createDebugElement } from "./placeholder";

export const DEFAULT_CUSTOMER_GROUP_DB = "dbpgk_maxblue_oth";
export const DEFAULT_CUSTOMER_GROUP_NORIS = "norisbank";

/**
 * Add a default customer group depending on the tenant.
 * @param {string} tenant
 */
export function getDefaultCustomerGroup(tenant) {
  return tenant === "noris"
    ? DEFAULT_CUSTOMER_GROUP_NORIS
    : DEFAULT_CUSTOMER_GROUP_DB;
}

export class CmsSnippet extends HTMLElement {
  connectedCallback() {
    this._config = {
      customerGroup:
        this.getAttribute("customergroup") ||
        getDefaultCustomerGroup(this.getAttribute("tenant")),
      position: this.getAttribute("position"),
      notificationId: this.getAttribute("notificationid") || undefined,
      answers: this.getSurveyAnswers()
    };
    window.addEventListener(
      "cms-store-change",
      event => {
        this.render(event.detail.state);
      },
      false
    );
    this.render(window.cmsStore.getState());
  }

  getSurveyAnswers = () => {
    if (!this.getAttribute("answers")) {
      return undefined;
    }
    return JSON.parse(this.getAttribute("answers").replace(/'/g, '"'));
  };

  renderDebug = config => {
    const debugElement = document.getElementById(
      `snippet_debug_${config.position}`
    );
    if (debugElement) {
      debugElement.remove();
    }
    this.appendChild(createDebugElement(config));
  };

  sendEvent(eventName) {
    try {
      this.dispatchEvent(new Event(eventName));
    } catch (error) {
      log(`could not send Event ${eventName}`);
    }
  }

  render(nextState) {
    const { config, snippets } = nextState;
    this._config = {
      ...this._config,
      ...config
    };

    if (this._config.disabled) {
      return null;
    }

    // cleaning snippet and setting height
    this.innerText = "";
    this.style.height = "";

    // renders debug element
    if (this._config.showDebugContent) {
      return this.renderDebug(this._config);
    }

    try {
      const currentSnippet = Snippet(snippets, this._config);

      // update snippet setting height
      this.innerText = "";
      this.style.height = currentSnippet.getHeight();

      currentSnippet
        .getHtml()
        .then(html => {
          this.innerHTML = html;
          this.sendEvent("load");
          // Snippets on position 1 means in the 'topicContainer' (left menu) and we need to force the 'heightBalancing' function
          // to check if the Snippet is covering the footer of the page.
          if (this._config.position === '1') {
            window.heightBalancing();
          }
        })
        .catch(() => {
          log("could not render snippet");
          this.sendEvent("error");
        });
    } catch (error) {
      this.innerHTML = "";
      this.sendEvent("abort");
    }
  }
}
