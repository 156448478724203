import { v4 as uuidv4 } from "uuid";

/**
 * Calls the apple music voucher endpoint of the voucher api.
 * @param {object} config
 * @returns a structure similar to this
 * {
 *  "code": "LPEF42UA6QJR",
 *   "url": "https://music.apple.com/redeem?ctx=Music",
 *   "redemption_url": "https://music.apple.com/redeem?ctx=Music&code=LPEF42UA6QJR",
 *   "end_date": "2022-01-30T23:59:59.999-08:00"
 * }
 */
export function fetchAppleMusicVoucher(config) {
  return window
    .fetch(`${config.apiServer}/trxmapi/v1/${config.tenant}/voucher/apple`, {
      mode: "cors",
      headers: {
        "X-License-Key": config.license,
        Authorization: `Bearer ${config.apiToken}`,
        "Accept-Language": config.language,
        "X-Correlation-ID": config.correlationId,
        "X-Request-ID": uuidv4()
      }
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        return Promise.reject(`Failed to invoke the trxm voucher api...`);
      }
    });
}
