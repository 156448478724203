/**
 * Fetches the html using the snippet url.
 * It returns a promise with the HTML code or a rejected promise is something
 * fails while fetching.
 * @param {string} url of the snippet
 * @param {object} config
 */
export function snippetHtmlFetcher(url, config) {
  return window
    .fetch(
      `${config.apiServer}/trxmapi/v1/${config.tenant}/services/snippets/cms/htmlcontent?snippetLocation=${url}`,
      {
        mode: "cors",
        headers: {
          "X-License-Key": config.license
        }
      }
    )
    .then(response => {
      if (response.status === 200) {
        return response.text();
      } else {
        return Promise.reject(`fetched snippet ${url} does not exist...`);
      }
    });
}
