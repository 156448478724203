import { log } from "../Logger";

export const ALL_PAGES = "all";
export const STORAGE_ID = "cms-store";

export function clean() {
  function clearKey(key) {
    if (localStorage.getItem(key) !== null) {
      log(`${key} removed from localStorage...`);
      localStorage.removeItem(key);
    }
  }

  clearKey(STORAGE_ID);
}
